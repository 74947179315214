<template>
    <div>
        <div class="dF aC ml-3 mb-5">
            <h5 class="mb-0">Video Conferencing</h5>
            <a-tooltip v-if="vendors.gmail.active" class="ml-3 mb-1" overlayClassName="change-tooltip-color">
                <template slot="title">
                    <div v-if="vendors.office365 && vendors.office365.active">To use the virtual appointment scheduler connect to either Teams or Zoom.</div>
                    <div v-if="vendors.outlook && vendors.outlook.active">To use the virtual appointment scheduler connect with Zoom.</div>
                    <div v-else>Google Meets is set as your default video conferencing provider. However, you can also connect to Zoom as an option.</div>
                </template>
                <a-icon type="question-circle" style="margin-top: 5px; color: var(--orange);" />
            </a-tooltip>
        </div>
        <div class="dF" style="justify-content:center">

            <div style="border:1px solid #E4E7EB; border-radius:4px; width:40%; text-align:center; padding:5%" class="mr-4">
                <img :src="require('@/assets/zoom.jpg')" style="width: 64px; height: 64px;" />
                <h5 class="mt-3">Zoom</h5>
                <p>In order to connect with Zoom you will need to have a Zoom account.</p>
                <template v-if="integrations.zoom && integrations.zoom.connectUrl && (!integrations.zoom.hasOwnProperty('active') || (integrations.zoom.active == false && integrations.zoom.hasOwnProperty('active')))">
                    <!-- <div>{{integrations.zoom.hasOwnProperty('active')}}</div> -->
                    <a :href="integrations.zoom.connectUrl"><a-button :disabled="integrations.teams && integrations.teams.connectUrl && integrations.teams.hasOwnProperty('active') && integrations.teams.active" type="primary" size="large">CONNECT</a-button></a>
                </template>
                <template v-else-if="integrations.zoom && integrations.zoom.hasOwnProperty('active') && integrations.zoom.active" && integrations.zoom.id>
                    <div style="color:var(--success)">Status: Connected <span v-if="connectedEmail">({{ connectedEmail }})</span></div>
                    <a-button @click="disconnect" class="mt-3 disconnect-button" size="large">DISCONNECT</a-button>
                </template>
            </div>

            <!-- <div v-if="vendors.office365 && vendors.office365.active" style="border:1px solid #E4E7EB; border-radius:4px; width:40%; text-align:center; padding:5%">
                <img :src="require('@/assets/teams.png')" style="width: 64px; height: 64px;" />
                <h5 class="mt-3">Teams</h5>
                <p>In order to connect with Teams you will need to have a Teams account.</p>
                <template v-if="integrations.teams && integrations.teams.connectUrl &&  (!integrations.teams.hasOwnProperty('active') || (integrations.teams.active == false && integrations.teams.hasOwnProperty('active')))">
                    <a :href="integrations.teams.connectUrl"><a-button :disabled="integrations.zoom && integrations.zoom.connectUrl && integrations.zoom.hasOwnProperty('active') && integrations.zoom.active" type="primary" size="large">CONNECT</a-button></a>
                </template>
                <template v-else-if="integrations.teams && integrations.teams.hasOwnProperty('active') && integrations.teams.active" && integrations.teams.id>
                    <div style="color:var(--success)">Status: Connected</div>
                    <a-button @click="disconnectTeams" class="mt-3 disconnect-button" size="large">DISCONNECT</a-button>
                </template>
            </div> -->

        </div>
    </div>
</template>

<script>
export default {
    computed:{
        integrations() {
            return this.$store.state.contacts.integrations || {}
        },
        vendors(){
            return this.$store.state.contacts.vendors || {}
        },
		connectedEmail() {
			if (this.integrations?.zoom?.active) {
				if (this.vendors?.gmail?.active && this.vendors.gmail?.other?.grant?.email) {
					return this.vendors.gmail.other.grant.email
				} else if (this.vendors?.office365?.active && this.vendors.office365?.other?.grant?.email) {
					return this.vendors.office365.other.grant.email
				} else if (this.vendors?.outlook?.active && this.vendors.outlook?.other?.grant?.email) {
					return this.vendors.outlook.other.grant.email
				} else {
					return false
				}
			}
			return false
		}
    },
    methods:{
        disconnect() {
            let self = this
            this.$confirm({
                title: 'Disconnect Zoom',
                content: h => <div>Do you want to disconnect your Zoom Account?</div>,
                okText: 'DISCONNECT',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    self.$api.delete(`/contacts/:instance/disconnect-integration/zoom`).then(({data}) => {
                        self.$store.commit('UPDATE_ZOOM_VENDOR', {active:false})
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        },
        disconnectTeams() {
            let self = this
            this.$confirm({
                title: 'Disconnect Teams',
                content: h => <div>Do you want to disconnect your Teams Account?</div>,
                okText: 'DISCONNECT',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    self.$api.delete(`/contacts/:instance/disconnect-integration/microsoft`).then(({data}) => {
                        self.$store.commit('UPDATE_TEAMS_VENDOR', {active:false})
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        }
    }
}
</script>

<style scoped>
    .disconnect-button{
        color:#FFF !important;
        background-color:var(--danger) !important;
    }
    .disconnect-button:hover{
        border-color:var(--danger) !important;
    }
</style>
